@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  font-family: "Lato", sans-serif;
  background-color: #ffffff !important;
  overflow-x: hidden;
}

input:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #ced4da !important;
}

select:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.input:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.text-sidebar {
  font-size: 14px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.sidebar-menu {
  padding: 13px;
}

.sidebar-menu:hover {
  background-color: #4f709c;
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.dropdown-toggle::after {
  border: none !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: white !important;
}

.bg-blue {
  background-color: #4f709c !important;
}

.text-blue {
  color: #4f709c !important;
}

.w-65 {
  width: 65% !important;
}

.text-xs {
  font-size: 0.7rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pagination{
  margin-top: 1rem!important;
}
